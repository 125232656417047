export const timeToDate = (timestamp) => {
  const dateObj = new Date(timestamp * 1000) // 创建Date对象
  const year = dateObj.getFullYear() // 获取年份
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2) // 获取月份，并补零
  const day = ('0' + dateObj.getDate()).slice(-2) // 获取日期，并补零
  const hours = dateObj.getHours().toString().padStart(2, '0') // 获取小时，需要补零
  const minutes = dateObj.getMinutes().toString().padStart(2, '0') // 获取分钟，需要补零
  const seconds = dateObj.getSeconds().toString().padStart(2, '0') // 获取秒数，需要补零
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` // 返回格式化后的日期字符串
}

export const fenToYuan = (fen) => {
  return (fen / 100).toFixed(2)
}

export const excelToJsonDate = (excelTimestamp) => {
  var dateObj = new Date((excelTimestamp - 25567) * 24 * 3600 * 1000)
  const year = dateObj.getFullYear() // 获取年份
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2) // 获取月份，并补零
  const day = ('0' + dateObj.getDate()).slice(-2) // 获取日期，并补零
  return `${year}-${month}-${day}` // 返回格式化后的日期字符串
}

export const ordernumToTime = (orderNum) => {
  const orderSplit = orderNum.split('-')[0]
  const orderNew = `20${orderSplit.slice(0, 2)}-${orderSplit.slice(2, 4)}-${orderSplit.slice(4, 6)}`
  return orderNew
}

/**
 * 解析 excel 导入的时间格式
 */
export const jsonToNewName = (obj, oldKey, newKey) => {
  Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey))
  delete obj[oldKey]
}

// Date 对象格式化为 yyyy-mm-dd
export const formatDate = (date) => {
  const year = date.getFullYear() // 获取年份
  const month = String(date.getMonth() + 1).padStart(2, '0') // 获取月份，+1 是因为 getMonth() 返回的月份是从 0 开始的
  const day = String(date.getDate()).padStart(2, '0') // 获取日期
  return `${year}-${month}-${day}`
}
